import { serviceProvider as API } from "../API/api";
import { Select, Textarea, TextInput } from "flowbite-react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import XLSX from 'xlsx';
import FormLabel from "../components/elements/FormLabel";


function round(number, decimalPlaces) {
    const factorOfTen = Math.pow(10, decimalPlaces);
    return Math.round(number * factorOfTen) / factorOfTen;
}

function abbrText(string, charNumber = 20) {
    return string.length <= charNumber + 3
        ? string
        : `${string.slice(0, charNumber)}...`;
}

function checkPassword(input) {
    const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    if (input.match(passw)) {
        return true;
    }
    else {
        return false;
    }
}

function newDate(endDate = false) {
    const date = new Date();
    date.setHours(endDate ? 23: 0);
    date.setMinutes(endDate ? 59 : 0);
    date.setSeconds(endDate ? 59 : 0);
    return date.toISOString();
}

function formatDate(isoString) {
    return new Date(isoString).toLocaleString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'Europe/Rome'
    });
}

function formatDateTime(isoString, weekday = false) {
    const options = weekday
        ? {
            weekday: 'long',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'Europe/Rome'
        }
        : {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'Europe/Rome'
        }
    return new Date(isoString).toLocaleString('it-IT', options);
}

function getTime(isoString, minutes = 0) {
    const date = new Date(isoString);
    const workDate = new Date();
    workDate.setTime(date);
    if (minutes) workDate.setTime(workDate.getTime() + minutes * 60 * 1000);
    return workDate.toLocaleString('it-IT', { hour: '2-digit', minute: '2-digit' });
}

function timePassed(date, pastDate) {
    const dateTimestamp = new Date(date).getTime() / 1000;
    const pastDateTimestamp = new Date(pastDate).getTime() / 1000;
    const difference = dateTimestamp - pastDateTimestamp;
    let output = ``;

    if (difference < -2620800) {
        // More than a month:
        output = `fra ${-Math.floor(difference / 2620800)} mesi`;
    } else if (difference < -86400) {
        // More than a day:
        output = `fra ${-Math.floor(difference / 86400)} giorni`;
    } else if (difference < -3600) {
        // More than a hour:
        output = `fra ${-Math.floor(difference / 3600)} ore`;
    } else if (difference < -60) {
        // More than a minute:
        output = `fra ${-Math.floor(difference / 60)} minuti`;
    } else if (difference < 0) {
        // More than a second:
        output = `fra ${-difference} secondi`;
    } else if (difference < 60) {
        // Less than a minute has passed:
        output = `${difference} secondi fa`;
    } else if (difference < 3600) {
        // Less than an hour has passed:
        output = `${Math.floor(difference / 60)} minuti fa`;
    } else if (difference < 86400) {
        // Less than a day has passed:
        output = `${Math.floor(difference / 3600)} ore fa`;
    } else if (difference < 2620800) {
        // Less than a month has passed:
        output = `${Math.floor(difference / 86400)} giorni fa`;
    } else if (difference < 31449600) {
        // Less than a year has passed:
        output = `${Math.floor(difference / 2620800)} mesi fa`;
    } else {
        // More than a year has passed:
        output = difference < 0 ? `fra più di un anno` : `più di un anno fa`;
    }
    return output;
}

function isSameDay(dateToTest, mainDate) {
    const startDate = new Date(dateToTest);
    startDate.setHours(0);
    startDate.setMinutes(0);
    const endDate = new Date(dateToTest);
    endDate.setHours(23);
    endDate.setMinutes(59);
    return startDate <= new Date(mainDate) && new Date(mainDate) <= endDate;
}

function formToIsoString(dateString) {
    // Se inizia con uno 0, ritorno dateString per prevenire crash
    if (!dateString) return dateString;
    let [ymd, time] = dateString.split('T');
    if (/^0/g.test(ymd)) {
        // Premetto 202* agli anni, per evitare fallimenti con inserimento da tastiera
        ymd = '202' + ymd.slice(3);
    }
    const [hours, minutes] = time.split(':');
    const date = new Date(ymd);
    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));
    return date.toISOString();
}

function isoToForm(isostring, type = 'date') {
    const date = new Date(isostring);
    const year = date.toLocaleString('it-IT', { year: 'numeric', timeZone: 'Europe/Rome' });
    const month = date.toLocaleString('it-IT', { month: '2-digit', timeZone: 'Europe/Rome' });
    const day = date.toLocaleString('it-IT', { day: '2-digit', timeZone: 'Europe/Rome' });
    const time = date.toLocaleString('it-IT', { timeStyle: 'short', timeZone: 'Europe/Rome' });
    return `${year}-${month}-${day}${type === 'datetime' ? `T${time}` : ''}`;
}

function randomNum(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function sortByName(array, property) {
    let prop1 = '';
    let prop2 = '';
    if (property.includes('.')) {
        [prop1, prop2] = property.split('.');
    } else {
        prop1 = property;
    }
    return [...array].sort((a, b) => {
        const nameA = prop2 ? a[prop1][prop2].toUpperCase() : a[prop1].toUpperCase();
        const nameB = prop2 ? b[prop1][prop2].toUpperCase() : b[prop1].toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
}

function sortByDate(array, property, order = 'asc') {
    let prop1 = '';
    let prop2 = '';
    if (property.includes('.')) {
        [prop1, prop2] = property.split('.');
    } else {
        prop1 = property;
    }
    return [...array].sort((a, b) => {
        const dateA = prop2 ? new Date(a[prop1][prop2]) : new Date(a[prop1]);
        const dateB = prop2 ? new Date(b[prop1][prop2]) : new Date(b[prop1]);
        return order === 'asc' ? dateA - dateB : dateB - dateA;
    })
}

function sortByValue(array, property) {
    let prop1 = '';
    let prop2 = '';
    if (property.includes('.')) {
        [prop1, prop2] = property.split('.');
    } else {
        prop1 = property;
    };
    return [...array].sort((a, b) => {
        const valueA = prop2 ? a[prop1][prop2] : a[prop1];
        const valueB = prop2 ? b[prop1][prop2] : b[prop1];
        return valueB - valueA;
    })
}

// Randomizza l'array di squadre fornito
function randomizeTeams(teams) {
    const randomList = [];
    for (let i = 0; i < teams.length; i += 1) {
        let num = randomNum(0, teams.length - 1);
        while (randomList.includes(teams[num])) {
            num = randomNum(0, teams.length - 1);
        }
        randomList.push(teams[num]);
    }
    return randomList;
}

// Funzione ricorsiva. Verifica che la data del match non sia tra quelle da escludere. Se lo è, modifica la data secondo i parametri forniti e richiama se stessa.
function checkDate(startDate, datesToSkip, team) {
    const { game_day, alt_day, alt_time } = team;
    const [hours, minutes] = team.game_time.split(':');
    const [alt_hours, alt_minutes] = alt_time.split(':');
    const matchDate = new Date(startDate);
    // Se la data del match non è presente nell'array, ritorno la data
    if (datesToSkip.every(d => formatDate(d) !== formatDate(matchDate))) {
        return matchDate;
    }
    // Se il giorno di gara e quello alternativo sono uguali, aggiungo una settimana, altrimenti scelgo l'alternativa
    const checkDay = game_day === alt_day ? game_day + 7 :
        matchDate.getDay() === game_day ? alt_day
            : game_day + 7;
    // Se la data è presente, cambio la data match e richiamo la funzione ricorsivamente
    setDay(matchDate, checkDay);
    matchDate.setHours(matchDate.getHours() === Number(hours) ? Number(alt_hours) : Number(hours));
    matchDate.setMinutes(matchDate.getMinutes() === Number(minutes) ? Number(alt_minutes) : Number(minutes));
    return checkDate(matchDate, datesToSkip, team);
}

// Data una data e un giorno della settimana, verifica la distanza e la sottrae/aggiunge (in JS non è integrato il metodo setDay nelle Date)
function setDay(date, day) {
    const currentDay = date.getDay();
    const distance = day - currentDay;
    return new Date(date.setDate(date.getDate() + distance));
}

// Verifica la data di partenza della giornata e la aggiorna di conseguenza
function checkStartDate(lastDay = [], startingDate) {
    // Se è la prima giornata, la data corretta è quella già fornita come parametro
    if (!lastDay.length) {
        return new Date(startingDate);
    }
    // Verifico il giorno della settimana che deve coincidere con quello di inizio della giornata
    const startDay = new Date(startingDate).getDay();
    // lastDay è un array contenente tutte le date della precedente data.
    // Lo mettiamo in ordine con sort per trovare la data inferiore tra tutte.
    const date = lastDay.sort((a, b) => a - b)[0];
    // Una volta trovata, aggiungiamo una settimana e poi correggiamo il valore portandolo a coincidere con startDay
    date.setDate(date.getDate() + 7);
    return setDay(new Date(date), startDay);
}

// Restituisce la data prevista del match, che andrà poi controllata mediante la funzione checkDate
function setMatchDate(startDate, team) {
    // La funzione dovrebbe già tenere conto dell'ora legale
    const matchDate = new Date(startDate);
    const { game_day, game_time } = team;
    // Se la data di gioco è inferiore al giorno della settimana, aggiungo 7 giorni
    const correctDay = game_day < matchDate.getDay() ? game_day + 7 : game_day;
    setDay(matchDate, correctDay);
    // Correzione ora di gioco
    const [hours, minutes] = game_time.split(':');
    matchDate.setHours(Number(hours));
    matchDate.setMinutes(Number(minutes));
    return matchDate;
}

// Funzione superiore per creare un calendario
function createCalendar(championship, type, startingNumber, datesToSkip, startingDate) {
    // Creo i gironi
    const groups = [...championship.teams];
    let startNum = Number(startingNumber);
    const pairings = groups.map(group => {
        // Per ciascun girone, creo i match
        const pairings = genPairings(randomizeTeams(group.teams), type === 'ritorno' ? true : false);
        const finalGroup = genMatches(pairings, startNum, startingDate, datesToSkip, championship);
        // Recupero dall'array l'ultimo match creato in questo girone
        const lastMatch = finalGroup[finalGroup.length - 1].matches[finalGroup[finalGroup.length - 1].matches.length - 1];
        // Genero il nuovo startNum
        startNum = lastMatch.num + 1;
        return { group_name: group.group_name, rounds: finalGroup };
    });

    return pairings;
}

function genPairings(teamsArray, createReturn = false) {
    const teams = [...teamsArray];

    const rounds = [];
    // Aggiunta di una "squadra" di comodo se sono dispari 
    (teams.length % 2) && teams.push({ name: 'dummy' });

    for (var i = 0; i < teams.length - 1; i += 1) {
        let day = { round: i + 1, matches: [] };
        for (var j = 0; j < teams.length / 2; j += 1) {
            // alterna le partite in casa e fuori
            day.matches.push(
                (i % 2) ?
                    [teams[teams.length - j - 1], teams[j]]
                    : [teams[j], teams[teams.length - j - 1]]
            );
        }
        rounds.push(day);
        day = [];
        // Ultima squadra viene inserita nella posizione 1
        teams.splice(1, 0, teams.pop());
    }
    // Se vero, crea il calendario di ritorno
    if (createReturn) {
        return rounds.concat(genReturn(rounds));
    };
    return rounds;
}

function genReturn(rounds) {
    const returnRounds = [];
    const numberOfRounds = rounds.length;
    rounds.forEach((round, i) => {
        returnRounds[i] = { round: numberOfRounds + i + 1, matches: [] };
        round.matches.forEach(match => {
            returnRounds[i].matches.push([match[1], match[0]]);
        });
    });
    return returnRounds;
}

function genMatches(pairings, startingNumber, startingDate, datesToSkip, championship) {
    const { _id: champId, season } = championship;

    let gameCount = startingNumber - 1; // Verrà aggiunto un numero alla prima iterazione
    let datesOfTheRound = [];

    const rounds = pairings.map(p => {
        let startDateOfTheRound = checkStartDate(datesOfTheRound, startingDate);
        datesOfTheRound = [];

        return {
            round: p.round,
            matches: p.matches
                .filter(pair => pair[0].name !== 'dummy' && pair[1].name !== 'dummy')
                .map(teams => {
                    let matchDate = setMatchDate(startDateOfTheRound, teams[0]);
                    matchDate = checkDate(matchDate, datesToSkip, teams[0]);
                    gameCount += 1;
                    datesOfTheRound.push(matchDate);
                    return {
                        championship: champId,
                        season: season,
                        round: p.round,
                        num: gameCount,
                        field: teams[0].game_field._id,
                        teamA: teams[0],
                        teamB: teams[1],
                        date: matchDate.toISOString()
                    }
                })
        }
    });

    return rounds;
}

// Function that stores into DB all matches in calendar
async function storeMatches(calendarArray) {
    // Edit the array to "depopulate" team fields
    const calendar = [...calendarArray].map(group => ({
        group_name: group.group_name,
        rounds: group.rounds.map(round => ({
            round: round.round,
            matches: round.matches
        }
        ))
    }));
    // Awaiting each save operation and returns the id of each match
    const result = calendar.map(async group => {
        const rounds = await Promise.all(group.rounds.map(async round => ({
            round: round.round,
            matches: await Promise.all(round.matches.map(async match => {
                const savedMatch = await API.insert('matches', JSON.stringify(match), true).catch(err => console.error(err));
                return savedMatch.match?._id;
            }))
        })));
        return {
            group_name: group.group_name,
            rounds: rounds
        }
    });

    return await Promise.all(result);
}

// Deletes all matches in an array
async function deleteMatches(matchesArray) {
    const matches = [...matchesArray];
    const result = matches.every(async match => {
        const result = await API.delete(`matches/${match}`, true).catch(err => console.error(err));
        return result?.success;
    });
    return result;
}

function findOverlapses(matchesArray) {
    const matches = [...matchesArray];
    const result = {
        byClub: [],
        byField: [],
        byTeam: []
    };
    const twoHours = 2 * 60 * 60 * 1000;
    for (let i = 0; i < matches.length; i) {
        // Scompongo l'array ed estraggo il primo elemento, che userò per compararlo con i restanti match
        const firstMatch = matches.shift();
        const dateFirstMatch = new Date(firstMatch.date);
        if (!firstMatch.postponed) {
            // Verifico che i 2 match siano nell'arco temporale di 2 ore
            const possibleOverlapses = matches
                .filter(m => !m.postponed && (new Date(m.date) - dateFirstMatch) >= -twoHours && (new Date(m.date) - dateFirstMatch) <= twoHours);
            // Verifichiamo tutte le possibili combinazioni
            possibleOverlapses.forEach(matchToTest => {
                if (
                    firstMatch.teamA._id === matchToTest.teamA._id
                    || firstMatch.teamA._id === matchToTest.teamB._id
                    || firstMatch.teamB._id === matchToTest.teamA._id
                    || firstMatch.teamB._id === matchToTest.teamB._id
                ) {
                    // Stessa squadra
                    result.byTeam.push([firstMatch, matchToTest]);
                }
                if (
                    firstMatch.teamA.club._id === matchToTest.teamA.club._id
                    || firstMatch.teamA.club._id === matchToTest.teamB.club._id
                    || firstMatch.teamB.club._id === matchToTest.teamA.club._id
                    || firstMatch.teamB.club._id === matchToTest.teamB.club._id
                ) {
                    // Stessa società
                    result.byClub.push([firstMatch, matchToTest]);
                }
                if (firstMatch.field._id === matchToTest.field._id) {
                    // Stesso campo
                    result.byField.push([firstMatch, matchToTest]);
                }
            });
        }
    }
    return result;
}

function calendarUrl(assignment) {
    function getTimeString(timeString) {
        return `${timeString.slice(6, 10)}${timeString.slice(3, 5)}${timeString.slice(0, 2)}T${timeString.slice(12, 14)}${timeString.slice(15)}00`;
    }
    const url = 'https://www.google.com/calendar/event?action=TEMPLATE';
    /* DATE SECTION */
    const date = formatDateTime(assignment.match?.date);
    const dateStr = `${date}`;
    const startTime = getTimeString(dateStr);
    const endTimeIso = new Date(assignment.match?.date);
    const workDate = new Date();
    workDate.setTime(endTimeIso);
    // Aggiungo 90 minuti per calcolare l'ora di fine
    workDate.setTime(workDate.getTime() + 90 * 60 * 1000);
    const endTimeStr = workDate.toLocaleString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Europe/Rome'
    });
    const endTime = getTimeString(endTimeStr);
    const dates = `${startTime}%2F${endTime}`;
    /* END DATE SECTION */
    const title = encodeURI(`Gara n. ${assignment.match.num} - ${assignment.match.teamA.name} / ${assignment.match.teamB.name}`);
    const details = encodeURI(`
    Riepilogo designazione:

    Campionato: ${assignment.match.championship.name}
    Gara n.: ${assignment.match.num}
    Squadra 1: ${assignment.match.teamA.name}
    Squadra 2: ${assignment.match.teamB.name}
    Campo di gioco: ${assignment.match.field.name}
    Indirizzo: ${assignment.match.field.city} (${assignment.match.field.province}) - ${assignment.match.field.address}
    Data: ${formatDateTime(assignment.match.date, true)}
    Ruolo: ${assignment.role}
    Referente Squadra: ${assignment.match.teamA.referent} (${assignment.match.teamA.referent_phone})

    Csisport.it
    `
        .replace('&', '')
        .replace('#', 'n.'));
    const location = encodeURI(`${assignment.match.field.name} - ${assignment.match.field.city} - ${assignment.match.field.address}`);
    const finalUrl = `${url}&dates=${dates}&ctz=Europe/Rome&text=${title}&location=${location}&details=${details}`
    return finalUrl;
}

function createFormElement(schema, disabled = true, useDefaults = true, value, onChange = () => undefined) {
    const { id, type, label_text, options, default_value } = schema;
    const divclass = 'text-justify';
    const formValue = useDefaults ? default_value : value;
    switch (type) {
        case 'text':
            return (
                <div className={divclass}>
                    <div className="mb-2 block">
                        <FormLabel htmlFor={id} label={label_text} />
                    </div>
                    <TextInput
                        id={id}
                        type="text"
                        value={formValue}
                        onChange={onChange}
                        disabled={disabled}
                    />
                </div>
            )
        case 'select':
            return (
                <div className={divclass}>
                    <div className="mb-2 block">
                        <FormLabel htmlFor={id} label={label_text} />
                    </div>
                    <Select
                        id={id}
                        value={formValue}
                        onChange={onChange}
                        disabled={disabled}
                    >
                        {
                            options.map((opt, i) => <option key={`opt_${i}`} value={opt.value}>{opt.label}</option>)
                        }
                    </Select>
                </div>
            );
        case 'textarea':
            return (
                <div className={divclass}>
                    <div className="mb-2 block">
                        <FormLabel htmlFor={id} label={label_text} />
                    </div>
                    <Textarea
                        id={id}
                        value={formValue}
                        onChange={onChange}
                        disabled={disabled}
                        rows={4}
                    />
                </div>
            )
        default:
    }
    return;
}

function verifyResult(result, possibleResults) {
    return possibleResults.some(r => r.a === Number(result.a) && r.b === Number(result.b));
}

function verifyPartials(result, partials, minPartials, maxSets) {
    if (maxSets === 0) return true; // Adattamento per il Calcio (CSI Carpi)
    const setsToCheck = result.a + result.b;
    const confirmPartials = { a: 0, b: 0 };
    const tests = partials.every((p, i) => {
        if (i >= setsToCheck) {
            return true;
        } else {
            // Verifico il risultato set
            if (p.a > p.b) confirmPartials.a += 1;
            if (p.b > p.a) confirmPartials.b += 1;
            // Verifico il conteggio set (se uguagliano il valore di maxSets, dev'essere l'ultimo ciclo)
            if (confirmPartials.a === maxSets && i !== setsToCheck - 1) {
                return false;
            }
            if (confirmPartials.b === maxSets && i !== setsToCheck - 1) {
                return false;
            }
            // Se i parziali superano il valore minimo, la differenza deve essere 2
            if (p.a > minPartials[i] && p.a > p.b) {
                if ((p.a - p.b) !== 2) return false;
            }
            if (p.b > minPartials[i] && p.b > p.a) {
                if ((p.b - p.a) !== 2) return false;
            }
            // I parziali devono essere quelli minimi e la differenza maggiore di 2
            return (p.a >= minPartials[i] || p.b >= minPartials[i]) && (p.a - p.b >= 2 || p.b - p.a >= 2);
        }
    });
    return tests && confirmPartials.a === result.a && confirmPartials.b === result.b;
}

function createPartials(result, partials) {
    const setsCount = result.a + result.b <= 5 ? result.a + result.b : 5; // Adattamento per il Calcio (CSI Carpi)
    const resultPartials = [];
    for (let i = 0; i < setsCount; i += 1) {
        resultPartials.push(partials[i]);
    }
    return resultPartials;
}

function readablePartials(partials) {
    return [...partials].map(p => `${p.a}-${p.b}`).join(', ');
}

function genUrlcode(string) {
    return string === undefined ? '' : string.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase();
}

function genRanking(teamsArray, matchesArray, penalties = []) {
    const teams = teamsArray.map(team => {
        const teamData = {
            _id: team._id,
            name: team.name,
            playedMatches: 0,
            points: 0,
            wonMatches: 0,
            lostMatches: 0,
            wonSets: 0,
            lostSets: 0,
            penalties: 0,
            qSets: 0,
            wonPoints: 0,
            lostPoints: 0,
            qPoints: 0
        }
        matchesArray
            .filter(m => m.teamA._id === team._id || m.teamB._id === team._id)
            .forEach(m => {
                if ((m.result.a + m.result.b) > 0) teamData.playedMatches += 1;
                // La squadra è il team A ?
                if (m.teamA._id === team._id) {
                    teamData.points += m.points.a;
                    if (m.result.a > m.result.b) teamData.wonMatches += 1;
                    if (m.result.a < m.result.b) teamData.lostMatches += 1;
                    teamData.wonSets += m.result.a;
                    teamData.lostSets += m.result.b;
                    teamData.wonPoints += m.partials.reduce((prev, curr) => prev + curr.a, 0);
                    teamData.lostPoints += m.partials.reduce((prev, curr) => prev + curr.b, 0);
                }
                // La squadra è il team B ?
                if (m.teamB._id === team._id) {
                    teamData.points += m.points.b;
                    if (m.result.b > m.result.a) teamData.wonMatches += 1;
                    if (m.result.b < m.result.a) teamData.lostMatches += 1;
                    teamData.wonSets += m.result.b;
                    teamData.lostSets += m.result.a;
                    teamData.wonPoints += m.partials.reduce((prev, curr) => prev + curr.b, 0);
                    teamData.lostPoints += m.partials.reduce((prev, curr) => prev + curr.a, 0);
                }
            });
        penalties.forEach(p => {
            if (p.team?._id === teamData._id) {
                teamData.penalties += p.value;
                teamData.points += p.value;
            }
        })
        teamData.qSets = round(teamData.lostSets !== 0 ? teamData.wonSets / teamData.lostSets : 69, 3);
        teamData.qPoints = round(teamData.lostPoints !== 0 ? teamData.wonPoints / teamData.lostPoints : 69, 3);
        return teamData;
    });
    return sortByValue(sortByValue(sortByValue(sortByValue(teams, 'qPoints'), 'qSets'), 'wonMatches'), 'points');
}

function genReceipt(payment, referee, committeeName) {
    const doc = new jsPDF('portrait');
    doc.setFont(undefined, 'bold').setFontSize(15);
    doc.text('RICEVUTA COMPENSO PER LAVORO SPORTIVO', 105, 20, { align: 'center' });
    doc.setFont(undefined, 'normal').setFontSize(11);
    doc.text(`
    Io sottoscritto __${referee.lastname} ${referee.firstname}__
    nato a ________________________ il ________________________, 
    residente a ________________________ 
    in via ________________________________________________ n. ___________
    Codice fiscale ________________________
    Per l'incarico di ________________________________________________
    Svolto nel periodo ________________________________________________
    `, 15, 30);
    doc.setFont(undefined, 'bold').setFontSize(14);
    doc.text('RICEVO', 95, 73);
    doc.setFont(undefined, 'normal').setFontSize(12);
    doc.text('A titolo di:', 15, 81);
    doc.rect(55, 78, 3, 3);
    doc.text('rimborso spesa', 63, 81);
    doc.text('Euro', 120, 81);
    doc.text(`${payment.meal + payment.travel + payment.expenses}`, 142, 81, { align: 'right' });
    doc.rect(55, 83, 3, 3);
    doc.text('indennità di trasferta', 63, 86);
    doc.text('Euro', 120, 86);
    doc.text(`${payment.car}`, 142, 86, { align: 'right' });
    doc.rect(55, 88, 3, 3);
    doc.text('lavoro sportivo', 63, 91);
    doc.text('Euro', 120, 91);
    doc.text(`${payment.allowance}`, 142, 91, { align: 'right' });
    doc.setFontSize(11);
    doc.text('su cui vogliate applicare la ritenuta INPS', 35, 98);
    doc.setFontSize(12);
    doc.text('Euro', 120, 98);
    doc.text('___', 142, 98, { align: 'right' });
    doc.setFont(undefined, 'bold').setFontSize(11);
    doc.text('TOTALE', 63, 105);
    doc.setFont(undefined, 'normal').setFontSize(12);
    doc.text('Euro', 120, 105);
    doc.text(`${payment.subtotal}`, 142, 105, { align: 'right' });
    doc.setFont(undefined, 'bold').setFontSize(14);
    doc.text('CERTIFICO', 92, 118);
    doc.setFont(undefined, 'normal').setFontSize(10);
    doc.rect(15, 125, 2, 2);
    doc.text('di nonessere iscritto in altre forme di previdenza (dipendente, artigiano, commerciante, casse di previdenza', 20, 127);
    doc.text('professionali ecc..) diverse da quelle della gestione separata INPS', 20, 131);
    doc.rect(15, 134, 2, 2);
    doc.text('di essere iscritto in altre forme di previdenza (dipendente, artigiano, commerciante, casse di previdenza', 20, 136);
    doc.text('professionali ecc..) diverse da quelle della gestione separata INPS, nello specifico ________________________', 20, 140);
    doc.text('che alla data odierna il presente compenso, percepito da:', 15, 146);
    doc.text(`${committeeName}`, 20, 150);
    doc.text('a fronte di prestazioni di lavoro sportivo', 15, 154);
    doc.rect(20, 158, 2, 2);
    doc.text('comporta', 25, 160);
    doc.rect(20, 162, 2, 2);
    doc.text('non comporta', 25, 164);
    doc.text('il superamento della franchigia di Euro 5.000 riferito all’anno solare __________', 15, 170);
    doc.text('ai fini dell’applicazione della ritenuta INPS e che', 15, 174);
    doc.rect(20, 178, 2, 2);
    doc.text('comporta', 25, 180);
    doc.rect(20, 182, 2, 2);
    doc.text('non comporta', 25, 184);
    doc.text('il superamento della soglia di esenzione IRPEF di Euro 15.000 (oltre la quale verranno emessi cedolini paga).', 15, 190);
    doc.text('Data ________________', 20, 220);
    doc.text('Firma _____________________________', 120, 220);
    doc.save(`ricevuta_${referee.lastname.toLowerCase()}_${formatDate(payment.emission_date).replace(/[/]/g, '')}.pdf`);
}

function genTeamPdf(championshipName, groupName, team, rounds, teams) {
    const doc = new jsPDF('landscape');
    doc.text(`${championshipName}, Girone ${groupName}`, 20, 20);

    const tableContent = [];

    rounds.forEach(r => {
        r.matches
            .filter(m => m.teamA._id === team || m.teamB._id === team)
            .forEach(m => {
                tableContent.push({
                    round: r.round,
                    number: `${m.num}`,
                    date: m.postponed ? 'da stabilire' : formatDateTime(m.date),
                    field: m.field.name,
                    address: `${m.field.city} - ${m.field.address}`,
                    teams: `${m.teamA.name} - ${m.teamB.name}`
                })
            })
    })

    autoTable(doc, {
        startY: 25,
        body: tableContent,
        columns: [
            { header: 'Giornata', dataKey: 'round' },
            { header: 'Num', dataKey: 'number' },
            { header: 'Data', dataKey: 'date' },
            { header: 'Campo', dataKey: 'field' },
            { header: 'Indirizzo', dataKey: 'address' },
            { header: 'Squadre', dataKey: 'teams' },
        ]
    })

    doc.addPage();

    const referentsHead = ['Squadra', 'Referente', 'Telefono', 'E-mail'];
    const referentsBody = teams.map(t => [t.name, t.referent, t.referent_phone, t.referent_email]);

    autoTable(doc, {
        head: [referentsHead],
        body: referentsBody
    })

    doc.save('table.pdf');
}

function genGroupPdf(championshipName, groupName, teams, rounds) {

    const doc = new jsPDF('landscape');

    doc.text(`${championshipName}, Girone ${groupName}`, 20, 20);

    const tableContent = [];

    rounds.forEach(r => {
        const teamsA = r.matches.map(m => m.teamA._id);
        const teamsB = r.matches.map(m => m.teamB._id);
        const restTeams = teams.filter(team => ![...teamsA, ...teamsB].includes(team._id));
        const restTeamsString = restTeams.reduce((prev, t) => `${prev} ${prev && '-'} ${t.name}`, '');

        tableContent.push({
            number: {
                content: `Giornata ${r.round}`,
                colSpan: 5,
                styles: { halign: 'center', fontStyle: 'bold', fontSize: 12 }
            }
        });

        r.matches.forEach(m => {
            tableContent.push({
                number: `${m.num}`,
                date: m.postponed ? 'da stabilire' : formatDateTime(m.date),
                field: m.field.name,
                address: `${m.field.city} - ${m.field.address}`,
                teams: `${m.teamA.name} - ${m.teamB.name}`
            })
        });

        tableContent.push({
            number: {
                content: `Riposo: ${restTeamsString}`,
                colSpan: 5,
                styles: { fontStyle: 'italic', fontSize: 8 }
            }
        })
    })

    autoTable(doc, {
        startY: 25,
        body: tableContent,
        columns: [
            { header: 'Num', dataKey: 'number' },
            { header: 'Data', dataKey: 'date' },
            { header: 'Campo', dataKey: 'field' },
            { header: 'Indirizzo', dataKey: 'address' },
            { header: 'Squadre', dataKey: 'teams' },
        ]
    })

    doc.addPage();

    const referentsHead = ['Squadra', 'Referente', 'Telefono', 'E-mail'];
    const referentsBody = teams.map(t => [t.name, t.referent, t.referent_phone, t.referent_email]);

    autoTable(doc, {
        head: [referentsHead],
        body: referentsBody
    })

    doc.save('table.pdf');
}

function genGroupXlsx(championshipName, matchRows, teamRows) {
    const worksheet1 = XLSX.utils.json_to_sheet(matchRows);
    const worksheet2 = XLSX.utils.json_to_sheet(teamRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet1, abbrText(championshipName, 25));
    XLSX.utils.book_append_sheet(workbook, worksheet2, 'Contatti');
    worksheet1["!cols"] = [{ wch: 10 }, { wch: 10 }, { wch: 15 }, { wch: 20 }, { wch: 25 }, { wch: 25 }, { wch: 25 }];
    worksheet2["!cols"] = [{ wch: 25 }, { wch: 20 }, { wch: 20 }];
    XLSX.writeFile(workbook, `${championshipName}.xlsx`);
}

// Crea un PDF per il pagamento
function genPaymentPdf(payment, referee) {

    const doc = new jsPDF('landscape');
    doc.text(`Riepilogo pagamento di ${referee.lastname} ${referee.firstname} del ${formatDate(payment.emission_date)} (Iban: ${referee.iban})`, 20, 20);

    const tableContent = [];

    payment.details.forEach(d => {
        tableContent.push({
            championship: d.assignment.match.championship.name,
            date: formatDateTime(d.assignment.match.date),
            match: `# ${d.assignment.match.num} / ${d.assignment.match.teamA.name} - ${d.assignment.match.teamB.name}`,
            allowance: `${d.allowance} €`,
            distance: `${d.distance} km`,
            car: `${d.car} €`,
            meal: `${d.meal} €`,
            travel: `${d.travel} €`,
            expenses: `${d.expenses} €`,
            subtotal: `${d.subtotal} €`
        })
    })

    tableContent.push({
        championship: '',
        date: '',
        match: 'TOTALI',
        allowance: `${payment.allowance} €`,
        distance: `${payment.distance} km`,
        car: `${payment.car} €`,
        meal: `${payment.meal} €`,
        travel: `${payment.travel} €`,
        expenses: `${payment.expenses} €`,
        subtotal: `${payment.subtotal} €`
    })

    autoTable(doc, {
        startY: 25,
        body: tableContent,
        bodyStyles: {fontSize: 6},
        headStyles: {fontSize: 8},
        columns: [
            { header: 'Campionato', dataKey: 'championship' },
            { header: 'Data', dataKey: 'date' },
            { header: 'Gara', dataKey: 'match' },
            { header: 'Diaria', dataKey: 'allowance' },
            { header: 'Dist.', dataKey: 'distance' },
            { header: 'Auto', dataKey: 'car' },
            { header: 'Pasti', dataKey: 'meal' },
            { header: 'Viaggi', dataKey: 'travel' },
            { header: 'Varie', dataKey: 'expenses' },
            { header: 'Totale', dataKey: 'subtotal' }
        ]
    })

    doc.save('pagamento.pdf');
}

// Crea un XLS per il pagamento
function genPaymentXls(payment, referee) {
    const { details } = payment;
    const data = details.map(d => ({
        allowance: `${d.allowance} €` || '0 €',
        car: `${d.car} €` || '0 €',
        carplate: d.carplate || 'n/a',
        championship: d.assignment.match.championship.name || '',
        departureArrival: d.departureArrival || '',
        departureTime: d.departureTime || '',
        distance: `${d.distance} km` || '0 km',
        end_time: d.end_time || '',
        expenses: `${d.expenses} €` || '0 €',
        match_number: d.assignment.match.num || '',
        meal: `${d.meal} €` || '0 €',
        notes: d.notes || '',
        returnArrival: d.returnArrival || '',
        returnTime: d.returnTime || '',
        start_time: d.start_time || '',
        subtotal: `${d.subtotal} €` || '0 €',
        teams: `${d.assignment.match.teamA.name} - ${d.assignment.match.teamB.name}`,
        travel: `${d.travel} €` || '0 €'
    }))
    const data_headers = [
        "match_number",
        "championship",
        "teams",
        "start_time",
        "end_time",
        "carplate",
        "departureTime",
        "departureArrival",
        "returnTime",
        "returnArrival",
        "distance",
        "allowance",
        "car",
        "meal",
        "travel",
        "expenses",
        "subtotal",
        "notes"
    ];
    const new_headers = [
        "# gara",
        "Campionato",
        "Squadre",
        "Ora inizio",
        "Ora fine",
        "Targa Auto",
        "Part. andata",
        "Arr. andata",
        "Part. ritorno",
        "Arr. ritorno",
        "Distanza",
        "Diaria",
        "Auto",
        "Pasti",
        "Viaggio",
        "Varie",
        "Totale",
        "Note"
    ];
    const totals_row = [
        '', 'TOTALI', '', '', '', '', '', '', '', '', `${payment.distance} km`, `${payment.allowance} €`, `${payment.car} €`, `${payment.meal} €`, `${payment.travel} €`, `${payment.expenses} €`, `${payment.subtotal} €`, ''
    ]
    const ws = XLSX.utils.json_to_sheet(data, { header: data_headers });
    XLSX.utils.sheet_add_aoa(ws, [new_headers], { origin: "A1" });
    XLSX.utils.sheet_add_aoa(ws, [totals_row], { origin: -1 });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, `${referee.lastname} ${referee.firstname}`);
    ws["!cols"] = [{ wch: 5 }, { wch: 25 }, { wch: 25 }, { wch: 8 }, { wch: 8 }, { wch: 10 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 8 }, { wch: 25 }];
    XLSX.writeFile(workbook, `${referee.lastname} ${referee.firstname}.xlsx`);
}

// Questa funzione inverte le squadre di una gara, il campo di gioco e aggiorna l'orario secondo le preferenze del nuovo team A
function invertMatch(originalMatch) {
    const match = {
        ...originalMatch,
        teamA: originalMatch.teamB,
        teamB: originalMatch.teamA,
        field: originalMatch.teamB.game_field
    }
    const [gameHours, gameMinutes] = match.teamA.game_time.split(':');
    const newDate = setDay(new Date(match.date), match.teamA.game_day);
    newDate.setHours(Number(gameHours));
    newDate.setMinutes(Number(gameMinutes));
    match.date = newDate.toISOString();
    return match;
}

function setAssignmentMail(match, assignment, committee, editedMatch = false, committeeMail) {
    const { assignments, championship, date, field, num, teamA, teamB } = match;
    const { referee, role } = assignment;
    const text = `
        Gentile ${referee.lastname} ${referee.firstname},
        ${editedMatch ? `la gara a cui sei designato è stata modificata come segue:` : `sei stato designato per la seguente gara:`}
        Campionato: ${championship.name}
        Gara #: ${num}
        Squadra 1: ${teamA.name}
        Squadra 2: ${teamB.name}
        Campo di gioco: ${field.name}
        Indirizzo: ${field.city} (${field.province}) - ${field.address}
        Data: ${formatDateTime(date, true)}
        Ruolo: ${role}

        Referente Squadra: ${teamA.referent} (${teamA.referent_phone})

        Ufficiali di gara designati per questa gara:
        ${assignments
            .filter(a => a.status > 0)
            .reduce((prev, a) =>
                `${prev} - ${a.role}: ${a.referee.lastname} ${a.referee.firstname} (${a.referee.phone})`, '')}.

        ${editedMatch ? 'Accedi al portale per accettare nuovamente la gara' : 'Rispondi alla designazione accedendo alla tua area riservata sul portale.'}
        Csisport.it
    `;
    const html = `
    <html>
        <head>
            <title></title>
        </head>
        <body style="cursor: auto;">
            <span style="background-color:rgb(255, 255, 255); color:rgb(34, 34, 34); font-family:arial,helvetica,sans-serif; font-size:small">
                Gentile ${referee.lastname} ${referee.firstname},
                <br />
                ${editedMatch ? `la gara a cui sei designato è stata modificata come segue:` : `sei stato designato per la seguente gara:`}
            </span>
            <br />
            &nbsp;
            <table border="0" cellpadding="1" cellspacing="1" style="width:500px">
                <tbody>
                    <tr>
                        <td>Campionato:</td>
                        <td>${championship.name}</td>
                    </tr>
                    <tr>
                        <td>Gara #:</td>
                        <td>${num}</td>
                    </tr>
                    <tr>
                        <td>Squadra1:</td>
                        <td>${teamA.name}</td>
                    </tr>
                    <tr>
                        <td>Squadra2:</td>
                        <td>${teamB.name}</td>
                    </tr>
                    <tr>
                        <td>Campo di gioco:</td>
                        <td>${field.name}</td>
                    </tr>
                    <tr>
                        <td>Indirizzo:</td>
                        <td>${field.city} (${field.province}) - ${field.address}</td>
                    </tr>
                    <tr>
                        <td>Data:</td>
                        <td>${formatDateTime(date, true)}</td>
                    </tr>
                    <tr>
                        <td>Ruolo:</td>
                        <td>${role}</td>
                    </tr>
                    <tr>
                        <td>Referente Squadra:</td>
                        <td>${teamA.referent} (${teamA.referent_phone})</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <br />
            <br />
            <span style="background-color:rgb(255, 255, 255); color:rgb(34, 34, 34); font-family:arial,helvetica,sans-serif; font-size:small">
                Ufficiali di gara designati per questa gara:
            </span>
            <br />
            <table border="0" cellpadding="1" cellspacing="1" style="width:500px">
                <tbody>
                ${assignments
            .filter(a => a.status > 0)
            .reduce((prev, a) =>
                `${prev}<tr><td>${a.role}:</td><td>${a.referee.lastname} ${a.referee.firstname} (${a.referee.phone})</td></tr>`, '')
        }
                </tbody>
            </table>
            <br />
            <span style="background-color:rgb(255, 255, 255); color:rgb(34, 34, 34); font-family:arial,helvetica,sans-serif; font-size:small">
            ${editedMatch ? 'Accedi al portale per accettare nuovamente la gara.' : 'Rispondi alla designazione accedendo alla tua area riservata sul portale.'}
            </span>
            <br />
            <a href="${calendarUrl(assignment)}">Aggiungi a Google Calendar</a>
            <br />
            <a href="https://www.csisport.it">Csisport.it</a>
        </body>
    </html>`;
    let response = {
        to: referee.email,
        subject: `${committee} - ${editedMatch ? `MODIFICA Des. Gara #${num}` : `Designazione Gara #${num}`}`,
        text,
        html
    };
    if (committeeMail) {
        response = {
            ...response,
            cc: committeeMail
        }
    }
    return response;
}

function setRemovalMail(match, assignment, committee, committeeMail) {
    const { championship, num, teamA, teamB } = match;
    const { referee, role } = assignment;
    const text = `
        Gentile ${referee.lastname} ${referee.firstname},
        
        la tua designazione, in qualità di ${role}, per la gara #${num} (${championship.name})
        tra ${teamA.name} e ${teamB.name}, prevista originariamente per il ${formatDateTime(match.date, true)},
        viene rimossa dal sistema, per uno dei seguenti motivi:
        
        - La gara è stata rinviata a data da destinarsi
        - La gara si giocherà tra molto tempo

        La presente è a scopo informativo: non è necessaria alcuna azione sul portale da parte tua.
        Csisport.it
    `;
    const html = `
        <html>
            <head>
                <title></title>
            </head>
            <body style="cursor: auto;">
                <div style="background-color:rgb(255, 255, 255); color:rgb(34, 34, 34); font-family:arial,helvetica,sans-serif; font-size:small">
                    Gentile ${referee.lastname} ${referee.firstname},
                    <br />
                    la tua designazione, in qualità di ${role}, per la gara #${num} (${championship.name}), tra ${teamA.name} e ${teamB.name}, prevista originariamente per il ${formatDateTime(match.date, true)},
                    <br />
                    viene <span style="font-weight: bold;">rimossa</span> dal sistema, per uno dei seguenti motivi:
                    <br />
                    <ul>
                        <li>La gara è stata rinviata a data da destinarsi</li>
                        <li>La gara si giocherà tra molto tempo</li>
                    </ul>
                    <br />
                    La presente è a scopo informativo: non è necessaria alcuna azione sul portale da parte tua.
                    <br />
                    Csisport.it
                </div>
            </body>
        </html>`;
    let response = {
        to: referee.email,
        subject: `${committee} - ANNULLAMENTO Des. Gara #${num}`,
        text,
        html
    }
    if (committeeMail) {
        response = {
            ...response,
            cc: committeeMail
        }
    }
    return response;
}

function setRejectMail(assignment, committee) {
    const { match } = assignment;
    const { championship, num, teamA, teamB } = match;
    const { reason, referee, role } = assignment;
    const text = `
        Gentile Designatore, 
        
        si comunica il rifiuto dell'arbitro ${referee.lastname} ${referee.firstname} relativa alla designazione, in qualità di ${role}, per la gara #${num} (${championship.name})
        tra ${teamA.name} e ${teamB.name} del ${formatDateTime(match.date, true)}.
        La motivazione inserita è la seguente:
        ${reason}
        Csisport.it
    `;
    const html = `
        <html>
            <head>
                <title></title>
            </head>
            <body style="cursor: auto;">
                <div style="background-color:rgb(255, 255, 255); color:rgb(34, 34, 34); font-family:arial,helvetica,sans-serif; font-size:small">
                    Gentile Designatore, 
                    <br />
                    si comunica il rifiuto dell'arbitro <strong>${referee.lastname} ${referee.firstname}</strong>
                    relativa alla designazione, in qualità di ${role}, per la gara #<strong>${num}</strong> (<strong>${championship.name}</strong>), 
                    tra <strong>${teamA.name}</strong> e <strong>${teamB.name}</strong> del <strong>${formatDateTime(match.date, true)}</strong>.
                    <br />
                    <br />
                    La motivazione inserita è la seguente:
                    <br />
                    <blockquote>${reason}</blockquote>
                    <br />
                    Csisport.it
                </div>
            </body>
        </html>`;
    return {
        to: championship.sport === 'Calcio' ? committee.soc_email : committee.vbl_email,
        subject: `${committee.title} - RIFIUTO Gara #${num}`,
        text,
        html
    }
}

function setPaymentMail(payment, referee) {
    const text = `
        Gentile ${referee.lastname} ${referee.firstname},
        è stato emesso un pagamento in data ${formatDate(payment.emission_date)} per le seguenti gare:

        ${payment.details.map(d => `
        Campionato: ${d.assignment.match.championship.name}
        Gara: ${d.assignment.match.teamA.name} - ${d.assignment.match.teamB.name}
        Diaria: ${d.allowance} €
        Distanza: ${d.distance} km
        Auto: ${d.car} €
        Pasti: ${d.meal} €
        Viaggi: ${d.travel} €
        Varie: ${d.expenses} €
        Totale: ${d.subtotal} €

        `)}
        Per un totale complessivo di € ${payment.subtotal}.
    `;
    const html = `
    <html>
        <head>
            <title></title>
        </head>
        <body style="cursor: auto;">
            <span style="background-color:rgb(255, 255, 255); color:rgb(34, 34, 34); font-family:arial,helvetica,sans-serif; font-size:small">
                Gentile ${referee.lastname} ${referee.firstname},
                <br />
                è stato emesso un pagamento in data ${formatDate(payment.emission_date)} per le seguenti gare:
            </span>
            <br />
            &nbsp;
            <table border="0" cellpadding="1" cellspacing="1" style="width:500px">
                <thead>
                    <tr>
                        <th>Campionato</th>
                        <th>Gara</th>
                        <th>Diaria</th>
                        <th>Distanza</th>
                        <th>Auto</th>
                        <th>Pasti</th>
                        <th>Viaggi</th>
                        <th>Varie</th>
                        <th>Totale</th>
                    </tr>
                </thead>
                <tbody>
                    ${payment.details.map(d => `
                        <tr>
                            <td>${d.assignment.match.championship.name}</td>
                            <td>${d.assignment.match.teamA.name} - ${d.assignment.match.teamB.name}</td>
                            <td>${d.allowance} €</td>
                            <td>${d.distance} km</td>
                            <td>${d.car} €</td>
                            <td>${d.meal} €</td>
                            <td>${d.travel} €</td>
                            <td>${d.expenses} €</td>
                            <td>${d.subtotal} €</td>
                        </tr>
                        `)
        }
                    <tr>
                        <td colspan="2">TOTALI</td>
                        <td>${payment.allowance} €</td>
                        <td>${payment.distance} km</td>
                        <td>${payment.car} €</td>
                        <td>${payment.meal} €</td>
                        <td>${payment.travel} €</td>
                        <td>${payment.expenses} €</td>
                        <td>${payment.subtotal} €</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <br />
            Cordialmente,
            <br />
            <a href="https://www.csisport.it">Csisport.it</a>
        </body>
    </html>`;
    return {
        to: referee.email,
        subject: `Csisport.it - Comunicazione pagamento`,
        text,
        html
    }
}

function mapsUrl(addressString) {
    const url = 'https://www.google.com/maps/search/?api=1&query=';
    return url + addressString
        .replace(' ', '%20')
        .replace(',', '%2C')
        .replace(/(?!%)[\W]/g, '%20');
}

function compareTime(startTime, endTime) {
    const [startHour, startMinutes] = startTime.split(':');
    const [endHour, endMinutes] = endTime.split(':');
    const startDate = new Date(1985, 4, 13);
    const endDate = new Date(1985, 4, 13);
    startDate.setHours(Number(startHour));
    startDate.setMinutes(Number(startMinutes));
    endDate.setHours(Number(endHour));
    endDate.setMinutes(Number(endMinutes));
    const threeHours = 3 * 60 * 60 * 1000;
    if ((endDate - startDate) === 0) return { status: 'danger', className: 'bg-red-200' };
    if (endDate - startDate <= threeHours) return { status: 'warning', className: 'bg-yellow-200' };
    return { status: 'success', className: 'bg-green-200' }
}

function timeDifferenceInDays(dateA, dateB) {
    const date1 = new Date(dateA);
    const date2 = new Date(dateB);
    const divisor = 24 * 60 * 60 * 1000;
    const result = (date1 - date2) / divisor;
    return result < 0 ? -result : result;
}

// Aggiunge tanti 0 fino a soddisfare length
function add0(value, length = 10, direction = 'left') {
    if (value.length >= length) return value;
    value = direction === 'left' ? '0' + value : value + '0';
    return add0(value, length);
}

function textAllowance(allowance) {
    const isInteger = Number.isInteger(allowance);
    const leftNum = isInteger ? add0(`${allowance}`, 4) : add0(`${allowance}`.split('.')[0], 4) ;
    const rightNum = isInteger ? '00' : add0(`${allowance}`.split('.')[1], 2, 'right') ;
    return `${leftNum}${rightNum}`;
}

function whitespaces(value) {
    let string = '';
    for (let a = 0; a < value; a += 1) {
        string += ' ';
    }
    return string;
}

// Se la giornata richiesta salta l'ordine, creo un array e una giornata vuota
function verifyDay(rounds, day) {
    //if (day < 2) return rounds;
    if (rounds.length <= day) return rounds;
    if (rounds.length === day - 1) {
        return rounds;
    }
    let r = [...rounds, {
        round: rounds.length + 1,
        matches: []
    }]
    return verifyDay(r, day)
}

export {
    abbrText,
    calendarUrl,
    checkPassword,
    compareTime,
    createCalendar,
    createFormElement,
    createPartials,
    deleteMatches,
    findOverlapses,
    formatDate,
    formatDateTime,
    formToIsoString,
    genGroupPdf,
    genGroupXlsx,
    genPaymentPdf,
    genPaymentXls,
    genRanking,
    genReceipt,
    genTeamPdf,
    genUrlcode,
    getTime,
    invertMatch,
    isoToForm,
    isSameDay,
    mapsUrl,
    newDate,
    readablePartials,
    setAssignmentMail,
    setPaymentMail,
    setRejectMail,
    setRemovalMail,
    sortByDate,
    sortByName,
    storeMatches,
    textAllowance,
    timeDifferenceInDays,
    timePassed,
    verifyDay,
    verifyPartials,
    verifyResult,
    whitespaces
};